import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const KreditTipps = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="10 Tipps für die Vorbereitung auf deinen Kredit" showCalc={false} />
            <Article>
                <p>
                    Du möchtest eine Immobilie kaufen? Eine großartige Idee. Wir haben für dich die 10 besten
                    Kredit-Tipps zusammengetragen. Wenn du also meinst, dass die Zeit für deine erste Wohnung oder dein
                    eigenes Haus gekommen ist, dann bist du hier genau richtig. Mit diesen 10 Ratschlägen bist du auf
                    der Suche nach dem perfekten Kredit immer einen Schritt voraus.
                </p>
                <hr />

                <h2>1. Mach deine Hausaufgaben – mit einer Haushaltsrechnung</h2>
                <p>
                    Das Wort „Hausaufgaben“ lässt dich vielleicht erstmal schlucken, aber diese Hausaufgabe ist den
                    Aufwand auf jeden Fall wert. Denn bei einer Haushaltsrechnung lernst du nicht nur was, sondern
                    kannst im besten Fall auch noch Geld sparen. Bei einer Haushaltsrechnung addierst du einfach alle
                    Einnahmen und ziehst dann alle Ausgaben ab. So siehst du, wie viel Geld dir im Monat übrig bleibt
                    und welchen Spielraum du für einen Kredit hast. Und der positive Nebeneffekt an diesem super
                    einfachen Kredit-Tipp: Du siehst endlich mal auf einen Blick, wofür du dein Geld wirklich ausgibst
                    und findest sicher den einen oder anderen Punkt, an dem du sparen kannst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Sprich mit einem Experten!
                    </a>
                </p>
                <hr />

                <h2>2. Verstehe die Grundlagen von Krediten</h2>
                <p>
                    Wenn du Kredit-Tipps suchst, hast du vielleicht gerade erst begonnen, dich mit dem Thema Kredit und
                    Hauskauf zu beschäftigen. Du solltest deshalb auf jeden Fall auch die Grundlagen von Krediten
                    kennen. Denn: Kredit ist nicht gleich Kredit. Es gibt sogar viele unterschiedliche Kreditarten, die
                    sich zum Beispiel bei der Laufzeit, der Rückzahlungsmöglichkeiten und der Zinsen unterscheiden. Es
                    kann nicht schaden, sich hier ein gewisses Grundwissen anzueignen, um im Ernstfall auch zu
                    verstehen, wovon dein Finanzierungsexperte spricht.
                </p>
                <hr />

                <h2>3. Informiere dich über deine Kreditwürdigkeit</h2>
                <p>
                    Deine Kreditwürdigkeit oder{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    ist ganz einfach deine prognostizierte Fähigkeit, einen Kredit zu bezahlen. Wenn du bei deiner
                    Hausbank – oder bei einem anderen Kreditinstitut – einen Kredit haben möchtest, wird diese also
                    anhand einiger Parameter deine Kreditwürdigkeit überprüfen. Aber warum solltest du das nicht auch
                    selbst machen? Es kann schließlich nicht schaden, Bescheid zu wissen. In Österreich gibt der
                    Kreditschutzverband (KSV) Auskunft über deine Kreditwürdigkeit. Alternativ kannst du dich
                    diesbezüglich aber auch mit einem unabhängigen Berater in Verbindung setzen.
                </p>
                <hr />

                <h2>4. Bring deine Finanzen in Ordnung</h2>
                <p>
                    Wenn du die Kredit-Tipps 1 bis 3 befolgt hast, weißt du jetzt also, wie viel Geld du wirklich hast,
                    wofür du es ausgibst, was ein Kredit ist und ob du aktuell überhaupt für einen Kredit in Frage
                    kommst. Super! Falls es aber noch Baustellen in deinen Finanzen gibt, dann bringe sie
                    schnellstmöglichst in Ordnung. Auch hier kann dich ein professioneller Berater oder eine Beraterin
                    unterstützen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Finanzberatung anfordern
                    </a>
                </p>
                <hr />

                <h2>5. Nicht nur beim Kredit ein guter Tipp: Sei ehrlich zu dir selbst</h2>
                <p>
                    Es ist dein großer Traum, deine eigenen Wohnung oder dein eigenes Haus zu besitzen – und das ist gut
                    so! Aber auch wenn man immer hart daran arbeiten sollte, sich seine Träume zu erfüllen, sollte man
                    auch realistisch bleiben. Du musst nicht gleich in einer Villa oder einem Innenstadt-Penthouse
                    wohnen. Zum großen Wohnglück braucht es meist nicht viel.
                </p>
                <hr />

                <h2>6. Hol dir Unterstützung</h2>
                <p>
                    Hier wollen wir gleich ganz ehrlich sein: Dieser Kredit-Tipp hängt natürlich stark von deinem
                    familiären und sonstigem Umfeld ab. Aber vielleicht hast du ja Familie und Verwandte, die dir bei
                    der Finanzierung deines Wohntraums ein wenig unter die Arme greifen können?
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>7. Hol dir nicht nur ein Kredit-Angebot</h2>
                <p>
                    Üblicherweise verläuft die Kreditentscheidung so: Der Wunsch nach einem Eigenheim entsteht, man
                    spricht mit Familie und Verwandten, man überprüft seine Finanzen und geht dann direkt zu seiner
                    Hausbank, bei der man seit Jahren Kunde ist. Alles perfekt, oder? Nicht ganz: Deine Bank mag in
                    Sachen Kontoführung ein guter Ansprechpartner sein, aber gerade bei Krediten gibt es unzählige
                    andere Anbieter, die sich vielleicht besser eignen. So vergleichen unabhängige Berater oft Kredite
                    von über 100 Instituten in Österreich. Und schauen am Ende natürlich bessere{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Konditionen
                    </Link>{" "}
                    für dich raus.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>8. Erstelle einen Tilgungsplan</h2>
                <p>
                    Wenn du – alleine oder mit der Hilfe von Experten den richtigen Kredit gefunden hast – solltest du
                    dich zusätzlich auch noch mit dem Thema Tilgungsplan beschäftigen. In einem Tilgungsplan werden kurz
                    gesagt folgende Themen behandelt:
                </p>
                <ul>
                    <li>Die Laufzeit des Kredits</li>
                    <li>Der Sollzinssatz</li>
                    <li>
                        Sondertilgungen – d.h. zusätzlichen Teilrückzahlungen, die du mit deiner Bank vereinbarst und
                        die dir dabei helfen können, die Tilgungsrate deines Kredits zu beeinflussen.
                    </li>
                </ul>
                <p>
                    Vor allem der dritte Punkt ist wichtig, denn es kann ja sein, dass du irgendwann auch eine größere
                    Rückzahlung auf einmal machen möchtest. Es empfiehlt sich deshalb, vorab zu vereinbaren, welche
                    Mehrkosten hier anfallen würden.
                </p>
                <hr />

                <h2>9. Sag niemals nie!</h2>
                <p>
                    Wenn du dir unsere Kredit-Tipps zu Herzen genommen hast, bist du womöglich der Meinung, dass ein
                    Kredit im Moment vielleicht doch nicht das richtige für dich ist. Aber wenn wir dir noch einen Tipp
                    mit auf deinen Weg geben dürfen: Sag niemals nie. Denn deine verfügbares Einkommen, deine Bonität
                    und auch deine sonstige finanzielle Lage, können sich in Zukunft noch ändern. Wenn du dich heute
                    über deine Möglichkeiten informierst, aber mit deiner Entscheidung noch eine Weile wartest, hast du
                    also auf jeden Fall gewonnen!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Erstgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h2>10. Frag Experten nach noch mehr Kredit-Tipps</h2>
                <p>
                    Wie du vielleicht festgestellt hast, kann das Thema{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    ein sehr komplexes sein. Wenn du allerdings die Grundlagen der Kreditarten kennst, wirst du dich in
                    diesem Gebiet bald wohler fühlen. Natürlich kann es nie schaden, sich noch mehr Kredit-Tipps zu
                    holen. Am besten von Experten und Beratern, die sich schon seit Jahren mit Immo-Finanzierung
                    beschäftigen und die Angebote und Möglichkeiten am Markt bestens kennen. Wenn du einige Minuten Zeit
                    hast, kannst du zum Beispiel unseren Online-Fragebogen ausfüllen und dich danach für ein kostenloses
                    Beratungsgespräch anmelden.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kreditTipps"}></BreadcrumbList>
            <ArticleStructuredData page={"kreditTipps"} heading={"10 Tipps für die Vorbereitung auf deinen Kredit"} />
        </Layout>
    );
};

export default KreditTipps;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kredit-tipps", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
